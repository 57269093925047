import { createSlice } from '@reduxjs/toolkit';
import {AdditionalRowType} from "../../components/feature/ManagePrice/BasePriceTable";
import {PriceItemPayload} from "../../components/feature/ManagePrice/BasePriceTable/BasePriceHelper";

interface StateType {
    savingState: '' | 'start' | 'preparing' | 'saving' | 'disabled';

    rows?: Record<string, PriceItemPayload[]>;
    additionalRows?: AdditionalRowType[][];

    rowsCount?: number;
}

const initialState: StateType = {
    savingState: '',
};

export const AddonPriceEditorSlice = createSlice({
    name: 'addonPriceEditor',
    initialState,
    reducers: {
        startSaving: (state: StateType) => {
            state.savingState = 'start';
        },

        resetSaving: (state) => {
            state.savingState = ""
        },

        setSaveDisabled: (state, action) => {
            const disabledState = action.payload;
            if(disabledState) {
                state.savingState = 'disabled';
            }
        },

        setRowsCount: (state, action) => {
            state.rowsCount = action.payload as number;
            state.savingState = action.payload > 0 ? 'preparing' : 'saving';
        },

        addRowData: (state, action) => {
            const {adonFeatureCode, rowItems} = action.payload as {
                adonFeatureCode: string;
                rowItems: PriceItemPayload[]
            };
            if(!state.rows) {
                state.rows = {};
            }
            state.rows[adonFeatureCode] = rowItems;

            if(Object.keys(state.rows).length === state.rowsCount) {
                state.savingState = 'saving';
            }
        },

        setSaved: (state) => {
            return { savingState: "" };
        }
    },
});

export const {
    startSaving,
    resetSaving,
    setSaveDisabled,
    setRowsCount,
    addRowData,
    setSaved
} = AddonPriceEditorSlice.actions;

export default AddonPriceEditorSlice.reducer;
