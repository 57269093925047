import {AppState, useSelector} from "./store";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import RTL from "../src/layouts/theme/full/shared/customizer/RTL";
import { ThemeSettings } from "../src/data/theme/Theme";
import CssBaseline from "@mui/material/CssBaseline";
import createEmotionCache from "./createEmotionCache";
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import React, {Suspense, lazy, LazyExoticComponent} from "react";
import routes from "./routes";
import {Toaster} from "react-hot-toast";
const clientSideEmotionCache = createEmotionCache();


const router = createBrowserRouter(routes);


function App() {
    const emotionCache = clientSideEmotionCache;
    const theme = ThemeSettings();
    const customizer = useSelector((state: AppState) => state.customizer);

    return (
          <CacheProvider value={emotionCache}>
              <ThemeProvider theme={theme}>
                  <RTL direction={customizer.activeDir}>
                      <CssBaseline />
                      <I18nextProvider i18n={i18n}>
                        <RouterProvider router={router} />
                          <Toaster />
                      </I18nextProvider>
                  </RTL>
              </ThemeProvider>
          </CacheProvider>
    );
}

export default App;

