import { createSlice } from '@reduxjs/toolkit';
import {FeatureItemPayload} from "../../pages/manage-features";

interface StateType {
    featuresData: {
        data: FeatureItemPayload|null;
        loadingState: '' | 'start' | 'loading' | 'loaded';
    };
    savingState: '' | 'start' | 'loading';
}

const initialState: StateType = {
    featuresData: {
        data: null,
        loadingState: ''
    },
    savingState: ''
};

export const FeaturesEditorSlice = createSlice({
    name: 'featuresEditor',
    initialState,
    reducers: {
        startLoadingFeaturesData: (state: StateType) => {
            if(state.featuresData.data) {
                state.featuresData.data = null;
            }
            state.featuresData.loadingState = 'start';
        },
        setLoadingFeaturesData: (state: StateType) => {
            state.featuresData.loadingState = 'loading';
        },
        setFeaturesData: (state: StateType, action) => {
            const featuresData: FeatureItemPayload = action.payload;
            state.featuresData.loadingState = 'loaded';
            state.featuresData.data = featuresData;
        },
        clearFeaturesData: (state) => {
            state.featuresData.data = null;
            state.featuresData.loadingState = '';
        },
        appendFeature: (state, action) => {
            const featureItem: FeatureItemPayload['features'][0] = action.payload;
            if(state.featuresData.data) {
                state.featuresData.data.features.push(featureItem);
            }
        },
        removeFeature: (state, action) => {
            const index: number = action.payload;
            if(state.featuresData.data) {
                state.featuresData.data.features = state.featuresData.data.features.filter((_, i) => index != i);
            }
        },
        modifyFeature: (state, action) => {
            const featureItem: FeatureItemPayload['features'][0] = action.payload;
            if(state.featuresData.data) {
                state.featuresData.data.features = state.featuresData.data.features.map(feat => {
                    if(feat.featureCode === featureItem.featureCode)
                        return { ...feat , ...featureItem };
                    return feat;
                })
            }
        },

        startSaving: (state) => {
            state.savingState = 'start';
        },
        setSaving: (state) => {
            state.savingState = 'loading'
        },
        setSaved: (state) => {
            state.savingState = '';
        }


    },
});

export const {
    startLoadingFeaturesData,
    clearFeaturesData,
    setLoadingFeaturesData,
    setFeaturesData,
    appendFeature,
    modifyFeature,
    removeFeature,

    startSaving, setSaving, setSaved
} = FeaturesEditorSlice.actions;

export default FeaturesEditorSlice.reducer;
