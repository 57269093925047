import { createSlice } from '@reduxjs/toolkit';
import {FeatureItemPayload} from "../../pages/manage-features";
import {AdditionalRowType} from "../../components/feature/ManagePrice/BasePriceTable";
import {PriceItemPayload} from "../../components/feature/ManagePrice/BasePriceTable/BasePriceHelper";

interface StateType {
    savingState: '' | 'start' | 'preparing' | 'saving' | 'disabled';

    rows?: Record<string, PriceItemPayload[]>;
    additionalRows?: AdditionalRowType[][];

    rowsCount?: number;
}

const initialState: StateType = {
    savingState: '',
};

export const PriceEditorSlice = createSlice({
    name: 'priceEditor',
    initialState,
    reducers: {
        startSaving: (state: StateType) => {
            state.savingState = 'start';
        },

        resetSaving: (state) => {
            state.savingState = ""
        },

        setSaveDisabled: (state, action) => {
            const disabledState = action.payload;
            if(disabledState) {
                state.savingState = 'disabled';
            }
        },

        setAdditionalRowsData: (state, action) => {
            state.additionalRows = action.payload as AdditionalRowType[][];
            if(state.rowsCount !== undefined && Number.isInteger(state.rowsCount)) {
                state.savingState = state.rowsCount > 0 ? 'preparing' : 'saving';
            }
        },

        setRowsCount: (state, action) => {
            state.rowsCount = action.payload as number;
            if(state.additionalRows) {
                state.savingState = action.payload > 0 ? 'preparing' : 'saving';
            }
        },

        addRowData: (state, action) => {
            const {rowId, rowItems} = action.payload as {
                rowId: string;
                rowItems: PriceItemPayload[]
            };
            if(!state.rows) {
                state.rows = {};
            }
            state.rows[rowId] = rowItems;

            if(Object.keys(state.rows).length === state.rowsCount) {
                state.savingState = 'saving';
            }
        },

        setSaved: (state) => {
            return { savingState: "" };
        }
    },
});

export const {
    startSaving,
    resetSaving,
    setSaveDisabled,
    setAdditionalRowsData,
    setRowsCount,
    addRowData,
    setSaved
} = PriceEditorSlice.actions;

export default PriceEditorSlice.reducer;
