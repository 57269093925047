import React, {lazy, Suspense} from "react";

const withSuspense = (WrappedComponent: any, fallback?: React.ReactNode) => (props: any) => (
    <Suspense fallback={fallback || null}>
        <WrappedComponent {...props} />
    </Suspense>
);

const AuthLogin = withSuspense(lazy(() => import('./pages/auth/login')))

const DashboardHome = withSuspense(lazy(() => import('./pages/dashboard/home')))
const DashboardTreeview = withSuspense(lazy(() => import('./pages/dashboard/treeview')))
const DashboardAddData = withSuspense(lazy(() => import('./pages/dashboard/add-data')))
const DashboardAddData1 = withSuspense(lazy(() => import('./pages/dashboard/add-data1')))
const DashboardCalendar = withSuspense(lazy(() => import('./pages/dashboard/calendar')))
const DashboardEcommerce = withSuspense(lazy(() => import('./pages/dashboard/ecommerce')))
const DashboardEditData = withSuspense(lazy(() => import('./pages/dashboard/edit-data')))
const DashboardEditData1 = withSuspense(lazy(() => import('./pages/dashboard/edit-data1')))
const DashboardModern = withSuspense(lazy(() => import('./pages/dashboard/modern')))
const DashboardProject = withSuspense(lazy(() => import('./pages/dashboard/projects')))


const DashboardTables_Basic = withSuspense(lazy(() => import('./pages/dashboard/tables/basic-table')))
const DashboardTables_Collapsible = withSuspense(lazy(() => import('./pages/dashboard/tables/collapsible-table')))
const DashboardTables_Enhanced = withSuspense(lazy(() => import('./pages/dashboard/tables/enhanced-table')))
const DashboardTables_Searchable = withSuspense(lazy(() => import('./pages/dashboard/tables/searchable-table')))

const FeatureAdd = withSuspense(lazy(() => import('./pages/features/add')));

const Tasklist = withSuspense(lazy(() => import('./pages/tasklist')))

const ManageManufacturers = withSuspense(lazy(() => import('./pages/manage-manufacturers')))
const AddManufacturerPage = withSuspense(lazy(() => import('./pages/manage-manufacturers/add')));
const EditManufacturerPage = withSuspense(lazy(() => import('./pages/manage-manufacturers/edit/[manufacturerCode]')));

const ManageBrands = withSuspense(lazy(() => import('./pages/manage-brands')))
const AddBrandPage = withSuspense(lazy(() => import('./pages/manage-brands/add')))
const EditBrandPage = withSuspense(lazy(() => import('./pages/manage-brands/edit/[brandCode]')));

const ManageSeries = withSuspense(lazy(() => import('./pages/manage-series')))
const AddSeriesPage = withSuspense(lazy(() => import('./pages/manage-series/add')))
const EditSeriesPage = withSuspense(lazy(() => import('./pages/manage-series/edit/[manufacturerCode]/[brandCode]/[seriesCode]')));
const EditSeriesPage1 = withSuspense(lazy(() => import('./pages/manage-series/edit/[manufacturerCode]/[seriesCode]')));

const ManageFeatures = withSuspense(lazy(() => import('./pages/manage-features')))
const AddFeaturesPage = withSuspense(lazy(() => import('./pages/manage-features/add')))
const EditFeaturesPage = withSuspense(lazy(() => import('./pages/manage-features/edit/[manufacturerCode]/[brandCode]/[seriesCode]/[featureCode]')));
const EditFeaturesPage1 = withSuspense(lazy(() => import('./pages/manage-features/edit/[manufacturerCode]/[seriesCode]/[featureCode]')));

const ManageAddonFeatures = withSuspense(lazy(() => import('./pages/manage-addon-features')))
const AddAddonFeaturesPage = withSuspense(lazy(() => import('./pages/manage-addon-features/add')))
const EditAddonFeaturesPage = withSuspense(lazy(() => import('./pages/manage-addon-features/edit/[manufacturerCode]/[brandCode]/[seriesCode]/[featureCode]')));
const EditAddonFeaturesPage1 = withSuspense(lazy(() => import('./pages/manage-addon-features/edit/[manufacturerCode]/[seriesCode]/[featureCode]')));

const ManagePrice = withSuspense(lazy(() => import('./pages/manage-price')))
const AddPricePage = withSuspense(lazy(() => import('./pages/manage-price/add')))
const EditPricePage = withSuspense(lazy(() => import('./pages/manage-price/edit/[manufacturerCode]/[brandCode]/[seriesCode]')));
const EditPricePage1 = withSuspense(lazy(() => import('./pages/manage-price/edit/[manufacturerCode]/[seriesCode]')));

const ManageAddonPrice = withSuspense(lazy(() => import('./pages/manage-addon-price')))
const AddAddonPricePage = withSuspense(lazy(() => import('./pages/manage-addon-price/add')))
const EditAddonPricePage = withSuspense(lazy(() => import('./pages/manage-addon-price/edit/[manufacturerCode]/[brandCode]/[seriesCode]')));
const EditAddonPricePage1 = withSuspense(lazy(() => import('./pages/manage-addon-price/edit/[manufacturerCode]/[seriesCode]')));

const ManagePricebookPage = withSuspense(lazy(() => import('./pages/manage-pricebook')));
const AddPricebookPage = withSuspense(lazy(() => import('./pages/manage-pricebook/add')));
const EditPricebookPage = withSuspense(lazy(() => import('./pages/manage-pricebook/edit/[manufacturerCode]/[brandCode]')));

const routes = [
    {
        path: "/",
        element: <DashboardHome />
    },
    {
        path: '/manage-pricebook',
        element: <ManagePricebookPage />
    },
    {
        path: '/manage-pricebook/add',
        element: <AddPricebookPage />
    },
    {
        path: "/manage-pricebook/edit/:manufacturerCode/:brandCode",
        element: <EditPricebookPage />
    },
    {
        path: "/dashboard/home",
        element: <DashboardHome />,
    },
    {
        path: "/features/add",
        element: <FeatureAdd />
    },
    {
        path: "/manage-manufacturers",
        element: <ManageManufacturers />
    },
    {
        path: "/manage-manufacturers/add",
        element: <AddManufacturerPage />
    },
    {
        path: "/manage-manufacturers/edit/:manufacturerCode",
        element: <EditManufacturerPage />
    },
    {
        path: "/manage-brands",
        element: <ManageBrands />
    },
    {
        path: "/manage-brands/add",
        element: <AddBrandPage />
    },
    {
        path: "/manage-brands/edit/:brandCode",
        element: <EditBrandPage />
    },
    {
        path: "/manage-series",
        element: <ManageSeries />
    },
    {
        path: "/manage-series/add",
        element: <AddSeriesPage />
    },
    {
        path: "/manage-series/edit/:manufacturerCode/:brandCode/:seriesCode",
        element: <EditSeriesPage />
    },
    {
        path: "/manage-series/edit/:manufacturerCode/:seriesCode",
        element: <EditSeriesPage1 />
    },
    {
        path: "/manage-features",
        element: <ManageFeatures />
    },
    {
        path: "/manage-features/add",
        element: <AddFeaturesPage />
    },
    {
        path: "/manage-features/edit/:manufacturerCode/:brandCode/:seriesCode/:featureCode",
        element: <EditFeaturesPage />
    },
    {
        path: "/manage-features/edit/:manufacturerCode//:seriesCode/:featureCode",
        element: <EditFeaturesPage1 />
    },
    {
        path: "/manage-addon-features/add",
        element: <AddAddonFeaturesPage />
    },
    {
        path: "/manage-addon-features/edit/:manufacturerCode/:brandCode/:seriesCode/:featureCode",
        element: <EditAddonFeaturesPage />
    },
    {
        path: "/manage-addon-features/edit/:manufacturerCode/:seriesCode/:featureCode",
        element: <EditAddonFeaturesPage1 />
    },
    {
        path: "/manage-addon-features",
        element: <ManageAddonFeatures />
    },
    {
        path: "/manage-price",
        element: <ManagePrice />
    },
    {
        path: "/manage-price/add",
        element: <AddPricePage />
    },
    {
        path: "/manage-price/edit/:manufacturerCode/:brandCode/:seriesCode",
        element: <EditPricePage />
    },
    {
        path: "/manage-price/edit/:manufacturerCode/:seriesCode",
        element: <EditPricePage1 />
    },
    {
        path: "/manage-addon-price",
        element: <ManageAddonPrice />
    },
    {
        path: "/manage-addon-price/add",
        element: <AddAddonPricePage />
    },
    {
        path: "/manage-addon-price/edit/:manufacturerCode/:brandCode/:seriesCode",
        element: <EditAddonPricePage />
    },
    {
        path: "/manage-addon-price/edit/:manufacturerCode/:seriesCode",
        element: <EditAddonPricePage1 />
    },
    {
        path: "/features/add",
        element: <FeatureAdd />
    },
    {
        path: "/features/add",
        element: <FeatureAdd />
    },
    {
        path: "/manage-manufacturers",
        element: <ManageManufacturers />
    },
    {
        path: "/manage-brands",
        element: <ManageBrands />
    },
    {
        path: "/manage-series",
        element: <ManageSeries />
    },
    {
        path: "/manage-features",
        element: <ManageFeatures />
    },
    {
        path: "/manage-addon-features",
        element: <ManageAddonFeatures />
    },
    {
        path: "/tasklist",
        element: <Tasklist />
    },
    {
        path: "/dashboard/projects",
        element: <DashboardProject />,
    },
    {
        path: "/dashboard/treeview",
        element: <DashboardTreeview />
    },
    {
        path: "/dashboard/add-data",
        element: <DashboardAddData />
    },
    {
        path: "/dashboard/add-data1",
        element: <DashboardAddData1 />
    },
    {
        path: '/dashboard/calendar',
        element: <DashboardCalendar />
    },
    {
        path: '/dashboard/ecommerce',
        element: <DashboardEcommerce />
    },
    {
        path: '/dashboard/edit-data',
        element: <DashboardEditData />
    },
    {
        path: '/dashboard/edit-data1',
        element: <DashboardEditData1 />
    },
    {
        path: '/dashboard/modern',
        element: <DashboardModern />
    },
    {
        path: '/auth/login',
        element: <AuthLogin />
    },
    {
        path: '/dashboard/tables/basic-table',
        element: <DashboardTables_Basic />
    },
    {
        path: '/dashboard/tables/collapsible-table',
        element: <DashboardTables_Collapsible />
    },
    {
        path: '/dashboard/tables/enhanced-table',
        element: <DashboardTables_Enhanced />
    },
    {
        path: '/dashboard/tables/searchable-table',
        element: <DashboardTables_Searchable />
    }
];


export default routes;
