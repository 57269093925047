import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import CustomizerReducer from './theme.slice';
import { combineReducers } from 'redux';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import FeaturesEditorReducer from "./reducers/featuresEditor.slice";
import PriceEditorReducer from "./reducers/priceEditor.slice";
import AddonFeaturesEditorReducer from "./reducers/addonFeaturesEditor.slice";
import AddonPriceEditorReducer from "./reducers/addonPriceEditor.slice";

const reducers = combineReducers({
  customizer: CustomizerReducer,
  featuresEditor: FeaturesEditorReducer,
  addonFeaturesEditor: AddonFeaturesEditorReducer,
  priceEditor: PriceEditorReducer,
  addonPriceEditor: AddonPriceEditorReducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["customizer"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
  // middleware: [thunk],
});

export const persistor = persistStore(store);

export const { dispatch } = store;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
>;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
